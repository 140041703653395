import React, { Fragment, useEffect, useState } from 'react';
import logo from "./assets/images/logo/noir.png"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { IconAlignJustified, IconX } from '@tabler/icons';
const LandingPageNav = () => {
    const [open, setOpen] = useState(false)
    return (
        <nav>
            <div>
                <img src={logo} />
                <button type="button" style={{ border: "0px", background: "white" }} onClick={() => { setOpen(!open) }} >{open ? <IconX style={{ animation: "fadein 2s" }} /> : <IconAlignJustified style={{ animation: "fadein 2s" }} />}</button>
            </div>

            <div className='landingPageLinks desktop'>
                <Link to="/">
                    Homes
                </Link>
                <Link to="/AboutUs">
                    About
                </Link>
                <Link to="/ContactUs">
                    Contact
                </Link>
                <Link style={{ color: "#b00000" }} to="/">
                    Sign In
                </Link>
            </div>
            <div style={{ display: open ? "flex" : "none" }} className='landingPageLinks Mobile'>
                <Link to="/">
                    Home
                </Link>
                <Link to="/AboutUs">
                    About
                </Link>
                <Link to="/ContactUs">
                    Contact
                </Link>
                <Link style={{ color: "#b00000" }} to="/">
                    Sign In
                </Link>
            </div>
        </nav>
    )
}

export default LandingPageNav