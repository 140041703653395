import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import { Link } from "react-router-dom"
const ContactUs = () => {
    const [bottonText, setBottonText] = useState("Contact us")
    const [errors, setErrors] = useState([])
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setmessage] = useState("")

    const validName = () => {
        if (name.trim().length < 5) {
            // setErrors([...errors, "not a vaild name"])
            errors.push("not a vaild name")
            return false
        }
        return true
    }
    const validEmail = () => {
        if (email.length < 8 && !email.includes("@") && !email.includes("@")) {
            errors.push("not a vaild email")
            return false
        }
        return true
    }
    const validMessage = () => {
        if (message.length < 3) {
            errors.push("not a vaild message")
            return false
        }
        return true
    }
    const submit = () => {
        let testEmail=validEmail()
        let testName=validName()
        let testMessage=validMessage()
        setErrors([...errors])

        if (testEmail && testName && testMessage) {
            setBottonText("✓ Sent")
        }else{
            setTimeout(() => {
                setErrors([])
            }, 5000);
        }
    }
    
    return (
        <div className='ContactUs'>
            <h1>Contact us</h1>
            <div className='ContactUsBody'>
                <div className='ContactUsForm'>
                    <div> { errors.map(el=>{
                        return(
                            <h6 style={{color : "red"}}>{el}</h6>
                        )
                    })} </div>
                    <label>Full Name:  <br /><input onChange={(e) => { setName(e.target.value) }} type="text" /></label>
                    <label>Email: <br /><input onChange={(e) => { setEmail(e.target.value) }} type="text" /></label>
                    <label>Message: <br /><input onChange={(e) => { setmessage(e.target.value) }} type="text" /></label>
                    <input onClick={() => { submit() }} value={bottonText} type="button" />
                </div>
                <div className='ContactUsInfo'>
                    <h2>Contact</h2>
                    <h3>Contact@hackup.io</h3>
                    <br />
                    <h2>Based in</h2>
                    <h3>B4, Mamia Palace 81, Av. Habib Bourguiba Ariana</h3>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <a href="https://www.facebook.com/hackupio"><i class="fa fa-facebook-square" aria-hidden="true"></i></a>
                        <a href="https://www.linkedin.com/company/hackupio/" ><i class="fa fa-linkedin-square" aria-hidden="true"></i></a>
                        <a href="https://www.instagram.com/hackup.io"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                        <a href="https://www.youtube.com/channel/UCoXbWsHCRcZk6WiiYORt8Cw"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs