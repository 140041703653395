import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import { Link } from "react-router-dom"
import team from ".././assets/images/logo/aboutus.jpg"
const AboutUs = () => {
    const Story = () => {
        return (
            <div>
                <h2>Our Story</h2>
                <div className='OurStory'>
                    <div>
                        <p>
                            Hack Up is a startup that was founded in July 2020 by Ayhem Ghanmi and Mohamed Aziz Najjar.
                        </p>
                        <p>
                            It is re-shaping the developers-recruiters matching by building a platform where recruiters hire based on developers’ scores.
                        </p>
                        <p>
                            Hack Up has organized more than 5 hackathons and became the first startup in the MENA region that specializes in them.
                        </p>
                        <p>
                            Thanks to it, Hack Up has welcomed more than 1000 developers in its community, a number that is growing by the hour.
                        </p>
                    </div>
                    <img src={team} />
                </div>
            </div>
        )
    }
    const Vision = () => {
        return (
            <div className='Vision'>
                <h2>Our Story</h2>
                <p >
                    “HACK UP WILL BE THE SINGLE-STOP STATION FOR ANY RECRUITERS
                    LOOKING FOR SKILLED AND QUALIFIED CANDIDATES AND AN INDISPENSABLE TOOL FOR EVERY DEVELOPER LOOKING FOR HIS DREAM JOB”
                </p>
            </div>
        )
    }
    const Values = () => {
        return (
            <div className='Values'>
                <h2>Our Values</h2>
                <div className='ValuesCards'>
                    <div >
                        <h3>Commitment</h3>
                        <p>We are committed to our community and to offer outstanding service through each exchange.</p>
                    </div>
                    <div >
                        <h3>Innovation</h3>
                        <p>We are working towards understanding our customers’ needs and responding with the utmost efficiency.</p>
                    </div>
                    <div >
                        <h3>Community</h3>
                        <p>Our community is our priority.</p>
                    </div>
                    <div >
                        <h3>Teamwork</h3>
                        <p>The hack Up team is working tightly together towards success.</p>
                    </div>
                </div>


            </div>
        )
    }
    return (
        <div className='AboutUs'>
            <h1>
                About us
            </h1>
            <Story />
            <Vision />
            <Values />
        </div>
    )
}

export default AboutUs