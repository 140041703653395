import React, { Fragment, useEffect, useState } from 'react';

const SecondContent =()=>{
    return(
        <div className='SecondContent'>
            <h1>
            Value Proposition !
            </h1>
            <p>
            “ Hack up offers a platform with a set of technical assessments tests and quizzes in order to reshape the recruitment process where recruiters will hire based on the developers’ skills. ”


            </p>
        </div>
    )
}

export default SecondContent