import React, { Fragment, useEffect, useState } from 'react';
import logo from "./assets/images/logo/blanc.png"
import { Link } from "react-router-dom"

const LandingPageFooter = () => {
    return (
        <footer>
            <div className='LandingPageFooterTop'>
                <div className='LandingPageFooterTopParagraph'>
                    <img src={logo} />
                    <p>HackUp is a developer assessment platform that helps companies to validate the developer’s skills through online coding tests.</p>
                </div>
                <div className='LandingPageFooterTopLists' >
                    <div>
                        <h1>HACKUP</h1>
                        <ul>
                            <li>
                                <Link to="/ "><u>HOME</u></Link>
                            </li>
                            <li>
                                <Link to="/about-us"><u>ABOUT US</u></Link>
                            </li>
                            <li>
                                <Link to="/contact-us"><u>CONTACT US</u></Link>
                            </li>
                            <li>
                                <Link to="/coutndown"><u>SIGN IN</u></Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h1>CONTACT</h1>
                        <ul>
                            <li>
                                contact@hackup.io
                            </li>
                            <li>
                                +216 90 243 876
                            </li>
                            <li>
                                B4, Mamia Palace 81, Av. Habib Bourguiba Ariana
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h1>LANGUAGE</h1>
                        <ul>
                            <li>
                                <Link to="/ "><u>English</u></Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h1>SOCIALS</h1>
                        <div>
                            <a href="https://www.facebook.com/hackupio"><i className="fa fa-facebook-square" aria-hidden="true"></i></a>
                            <a href="https://www.linkedin.com/company/hackupio/" ><i className="fa fa-linkedin-square" aria-hidden="true"></i></a>
                            <a href="https://www.instagram.com/hackup.io"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                            <a href="https://www.youtube.com/channel/UCoXbWsHCRcZk6WiiYORt8Cw"><i className="fa fa-youtube-play" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='LandingPageFooterBottom'>
                Built by HackUp. Copyright © 2022
            </div>
        </footer>
    )
}

export default LandingPageFooter