import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import consolePicture from ".././assets/images/logo/console.png"
const FourthContent = () => {
    return (
        <div className='FourthContent'>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                <h1>
                    Start Taking Credits For Your Skills With Quizzes And Tests!
                </h1>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <Button color="red" radius="md" size="lg" uppercase>
                        <a href="http://developer.hackup.io">
                            Browse Problems
                        </a>
                    </Button>
                    <Button color="red" radius="md" size="lg" uppercase>
                        <a href="http://developer.hackup.io">
                            Browse Quizzes
                        </a>
                    </Button>
                </div>
            </div>
            <img src={consolePicture} />
        </div>
    )
}

export default FourthContent