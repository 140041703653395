import React, { Fragment, useEffect, useState } from 'react';
import Gamepad from ".././assets/images/logo/Gamepad.png"
import Dimond from ".././assets/images/logo/Dimond_alt.png"
import Question from ".././assets/images/logo/Question.png"
import View from ".././assets/images/logo/View.png"

const ThirdContent = () => {
    return (
        <div className='ThirdContent'>
            <div>
                <img src={Gamepad} />
                <h2>
                    Play with code
                </h2>
                <p>
                    A space where coding will become a child's play.
                </p>
            </div>
            <div>
                <img src={Dimond} />
                <h2>
                    Challenge yourself
                </h2>
                <p>
                    “+200” number of tests to cover more than “10” number of languages                </p>
            </div>
            <div>
                <img src={Question} />
                <h2>
                    Get noticed
                </h2>
                <p>
                    A match-maker between developers and recruiters
                </p>
            </div>
            <div>
                <img src={View} />
                <h2>
                    Be supported
                </h2>
                <p>
                    Top notch team to ensure your requests in record time
                </p>
            </div>
        </div>
    )
}

export default ThirdContent