import React, { Fragment, useEffect, useState } from 'react';
import LandingPageNav from "./landingPageNav"
import LandingPageFooter from "./landingPageFooter"
import FistContent from "./content/fistContent"
import SecondContent from "./content/secondContent"
import ThirdContent from "./content/thirdContent"
import FourthContent from "./content/fourthContent"
import FifthContent from "./content/fifthContent"
import SixthContent from "./content/sixthContent"
import AboutUs from "./content/aboutUs"
import ContactUs from "./content/contact"
import { Route, Routes } from "react-router-dom"
// css
import './app.scss';

const App = () => {
  return (
    <div className='landingPage'>
      <LandingPageNav />
      <Routes>
        <Route path="/" element={
          <>
            <FistContent />
            <SecondContent />
            <ThirdContent />
            <FourthContent />
            <FifthContent />
            <SixthContent />
          </>
        }>
        </Route>
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
      </Routes>
      <LandingPageFooter />
    </div>
  )
}

export default App;
