import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import {Link} from "react-router-dom"
const SixthContent = () => {
    return (
        <div className='SixthContent'>
            <h1>
                Start your developer’s journey with< br/> Hack<span style={{ color: "#b20000" }}>Up</span> !
            </h1>
            <Button color="red" radius="md" size="lg" uppercase>
                <Link to="/contact-us">
                    Get in touch with us
                </Link>
            </Button>
        </div>
    )
}

export default SixthContent