import React, { Fragment, useEffect, useState } from 'react';
import { Text, Title, Card } from '@mantine/core';
import QuoteMark from ".././assets/images/logo/QuoteMark.png"
const FifthContent = () => {
    return (
        <div className='FifthContent'>
            <Title size={42} weight={800} order={1}>Real Stories From</Title>
            <Title size={42} weight={800} order={1}>Our Community</Title>
            <div className='FifthContentlist'  >
                <Text size="lg">Read What They Say About HackUp.</Text>
                <Card style={{ display: "flex" }} shadow="sm" p="lg" radius="md" withBorder>
                    <img src={QuoteMark} />
                    <div>
                        <Text size="sm" color="dimmed">
                            Working as an intern at HackUp was an eye-opening opportunity. Thanks to it, I got a hands-on experience in working using a scrum environment while diving into the codebase which utilizes software engineering best practices. Additionally, I had the chance to work alongside brilliant and friendly developers through collaboration tools and daily meetings.
                        </Text>
                        <Text weight={500}>Aziz Amari</Text>

                    </div>
                </Card>
                <Card style={{ display: "flex" }} shadow="sm" p="lg" radius="md" withBorder>
                    <img src={QuoteMark} />
                    <div>
                        <Text size="sm" color="dimmed">
                            It was a pleasure to be participating in all HackUp’s organized hackathons. Fin’hack was one of the best-organized events; the mentors were extremely supportive and gave us excellent guidance on how to improve our project, which came in third place.
                        </Text>
                        <Text weight={500}>Oumayma Rjab</Text>

                    </div>
                </Card>
                <Card style={{ display: "flex" }} shadow="sm" p="lg" radius="md" withBorder>
                    <img src={QuoteMark} />
                    <div>
                        <Text size="sm" color="dimmed">
                            HackUp hackathons were a great opportunity to network with the participants and especially the mentors. They were so helpful and they gave us precious advice to build an innovative solution and win. Thank you HackUp for these events.                        </Text>
                        <Text weight={500}>Amira Dridi</Text>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default FifthContent