import React, { Fragment, useEffect, useState } from 'react';
import { Button } from '@mantine/core';
import landingPicture from ".././assets/images/logo/loadingpage.png"
const FistContent = () => {
    return (
        <div className='FistContent'>
            <div>
                <h1>
                    It’s All About The

                    <span style={{ color: "#b00000" }}>
                        Community !
                    </span>
                </h1>
                <p>
                    Join our community of developers by creating an
                    account on our platform !
                </p>
                <Button color="red" radius="md" size="lg" uppercase>
                    <a href="http://developer.hackup.io">
                        Create an account
                    </a>
                </Button>
            </div>
            <img src={landingPicture} />
        </div>
    )
}

export default FistContent